<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 32 32"
	enable-background="new 0 0 32 32"
	xml:space="preserve"
	class={$$restProps.class || ''}
>
	<path
		fill="currentColor"
		d="M26.323,17.807c0.054-0.07,0.105-0.136,0.163-0.212c0.197-0.262,0.254-0.604,0.152-0.915
	c-0.103-0.311-0.351-0.553-0.665-0.646C25.464,15.883,25,15.81,24.555,15.81c-0.255,0-0.5,0.038-0.744,0.075L22.3,10.6
	c0.222,0.13,0.478,0.211,0.755,0.211h0.5v-3h-0.5c-0.652,0-1.201,0.419-1.408,1h-4.592c-0.276,0-0.5,0.224-0.5,0.5
	s0.224,0.5,0.5,0.5h2.507c-0.313,0.311-0.507,0.741-0.507,1.216v9.285h-3.186l-1.753-3.647l0.878-1.614
	c0.071-0.13,0.08-0.285,0.025-0.423c-0.055-0.138-0.168-0.245-0.309-0.291l-0.156-0.051V14.21c0-1.049-0.851-1.9-1.9-1.9h-3.7
	c-1.049,0-1.9,0.851-1.9,1.9v0.596c-1.817,1.366-3,3.539-3,6.004c0,0.276,0.224,0.5,0.5,0.5h2.051c0.245,1.692,1.691,3,3.449,3
	s3.204-1.308,3.449-3h2.04c0.003,0,0.007,0,0.011,0c0.003,0,0.007,0,0.011,0h3.989c0.014,0,0.028-0.007,0.042-0.008
	c0.001,0.009,0.002,0.023,0.003,0.032l0.014,0.1c0.063,0.5,0.488,0.875,0.992,0.875h0.801c0.564,1.178,1.758,2,3.149,2
	c1.93,0,3.5-1.57,3.5-3.5C28.055,19.528,27.354,18.416,26.323,17.807z M10.055,23.311c-1.208,0-2.217-0.86-2.45-2h4.899
	C12.272,22.45,11.262,23.311,10.055,23.311z M13.555,20.311h-7H5.071c0.127-1.807,1.004-3.406,2.317-4.5h6.054l-0.326,0.6
	c-0.077,0.141-0.081,0.311-0.011,0.456l1.656,3.445H13.555z M20.055,18.67v-7.644c0-0.395,0.321-0.716,0.716-0.716h0.407
	l1.663,5.822C21.612,16.584,20.615,17.497,20.055,18.67z M24.555,23.31c-0.923,0-1.72-0.506-2.153-1.25
	c0.088-0.076,0.167-0.161,0.225-0.266c0.134-0.243,0.203-0.349,0.236-0.395c0.331-0.454,1.068-1.153,1.718-1.77
	c0.393-0.372,0.766-0.728,1.079-1.052c0.824,0.409,1.395,1.25,1.395,2.232C27.055,22.191,25.935,23.31,24.555,23.31z"
	/>
</svg>
